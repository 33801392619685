import { useGraphQL } from 'src/hooks/useGraphQL'
import { GetTData } from 'src/gql/helpers'
import { graphql } from 'src/gql'
import { Option } from 'src/components/Forms/Checkboxes'
import { SortFindManyCat_ContractInput, SortFindManyPosition_StatusInput } from 'src/gql/graphql'
import { createCheckboxOptions } from 'src/helpers/formHelpers'

const document = graphql(/* graphql */`
  query positionFilterLookups (
    $ContractFilter: FilterFindManyCat_ContractInput,
    $StatusFilter: FilterFindManyPosition_StatusInput
    $ContractTypeFilter: FilterFindManyContract_TypeInput,
    $ContractSort: SortFindManyCat_ContractInput,
    $StatusSort: SortFindManyPosition_StatusInput
    $ContractTypeSort: SortFindManyContract_TypeInput
  ) {
    lookups {
      Cat_Contract {
        many(filter: $ContractFilter, sort: $ContractSort) {
          _id
          name
        }
      }
      Position_Status {
        many(filter: $StatusFilter, sort: $StatusSort) {
          _id
          name
        }
      }
      Contract_Type {
        many(filter: $ContractTypeFilter, sort: $ContractTypeSort) {
          _id
          name
        }
      }
    }
  }
`)

export type PositionFilterLookups = NonNullable<GetTData<typeof document>>['lookups']

interface FilterLookups {
  Contract_Type: Option[]
  Cat_Contract: Option[]
  Position_Status: Option[]
}

const usePositionFilterLookups = (): FilterLookups => {
  const { data } = useGraphQL(document, {
    ContractSort: SortFindManyCat_ContractInput.NameAsc,
    StatusSort: SortFindManyPosition_StatusInput.OrderAsc
  })
  const filters = {
    Cat_Contract: createCheckboxOptions(data?.lookups?.Cat_Contract?.many ?? [], 'contract'),
    Position_Status: createCheckboxOptions(data?.lookups?.Position_Status?.many ?? [], 'status'),
    Contract_Type: createCheckboxOptions(data?.lookups?.Contract_Type?.many ?? [], 'contract_type')
  }
  return filters
}

export { usePositionFilterLookups, document as positionFilterLookupsDocument }
