import React from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  RouteObject
} from 'react-router-dom'
import Page from './components/theme/Page'
import App from './App'
import PositionList from './pages/cat/positions/List'
import Position from './pages/cat/positions/Position'
import Candidate from './pages/cat/candidates/Candidate'
import PositionCandidates from './pages/cat/positions/Candidates'
import Distribution from './pages/cat/distributions/Distribution'
import SplashPage from './components/theme/SplashPage'
import Login from './pages/Login'
import { RequireUser } from './hooks/useAuth'
import RouterError from './components/theme/Errors/RouterError'
import Search from './pages/search'
import useAnalytics from 'src/hooks/useAnalytics'
import JobDescriptions from 'src/pages/jobdescriptions'
import DistributionOverview from './pages/cat/view/distributionoverview'
import Management from 'src/pages/management'

const catViews: RouteObject[] = [
  {
    path: 'distributionoverview',
    element: <DistributionOverview />
  }
]

const cat: RouteObject[] = [
  {
    index: true,
    element: <PositionList />
  },
  {
    path: 'positions',
    element: <PositionList />
  },
  {
    path: 'positions/:id',
    element: <Position />
  },
  {
    path: 'positions/:id/candidates',
    element: <PositionCandidates />
  },
  {
    path: 'distributions/:id',
    element: <Distribution />
  },
  {
    path: 'candidates/:id',
    element: <Candidate />
  },
  {
    path: 'views',
    children: catViews,
    element: (
      <RequireUser permissionKey='CAT'>
        <Outlet />
      </RequireUser>
    )
  }
]

export const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <RequireUser>
        <Page>
          <Outlet />
        </Page>
      </RequireUser>
    ),
    errorElement: (
      <RouterError />
    ),
    children: [
      {
        index: true,
        path: '/',
        element: <App />
      },
      {
        path: '/cat',
        children: cat,
        element: (
          <RequireUser permissionKey='CAT'>
            <Outlet />
          </RequireUser>
        )
      },
      {
        path: '/management',
        element: (
          <RequireUser permissionKey='MANAGEMENT'>
            <Management />
          </RequireUser>
        )
      },
      {
        path: '/search',
        element: (
          <RequireUser permissionKey='SEARCH'>
            <Search />
          </RequireUser>
        )
      },
      {
        path: '/jobdescriptions',
        element: (
          <RequireUser permissionKey='JOB_DESC_DB'>
            <JobDescriptions />
          </RequireUser>
        )
      }
    ]
  },
  {
    element: (
      <SplashPage>
        <Outlet />
      </SplashPage>
    ),
    errorElement: (
      <RouterError />
    ),
    children: [
      {
        path: '/login',
        element: <Login />
      }
    ]
  }
]

const router = createBrowserRouter(routes, {
  basename: process.env.REACT_APP_PUBLIC_URL ?? '/'
})

const Router: React.FC = (props) => {
  useAnalytics()
  return (
    <div id='wrapper'>
      <RouterProvider router={router} />
    </div>
  )
}

export default Router
