import { v4 as uuid } from 'uuid'
import {
  faBriefcase,
  faFileLines,
  faChartColumn,
  faGear
} from '@fortawesome/free-solid-svg-icons'
import { LinkProps } from 'react-router-dom'

import { IconDefinition } from '@fortawesome/fontawesome-common-types'

export interface MenuCategory {
  title: string
  id: string
  menu: Array<MenuItemWithSubmenu | MenuItemWithoutSubmenu>
  permissions?: string
}

interface MenuItemWithSubmenu {
  title: string
  id: string
  icon: IconDefinition
  onClick?: () => void
  permissions?: string
  submenu?: Array<{
    id: string
    title: string
    to?: LinkProps['to']
    onClick?: () => void
    permissions?: string
  }>
}

interface MenuItemWithoutSubmenu extends Omit<MenuItemWithSubmenu, 'submenu'> {
  to?: LinkProps['to']
}

export type SidebarMenu = MenuCategory[]

const menu: SidebarMenu = [
  {
    title: 'Recruiting',
    id: uuid(),
    menu: [
      {
        title: 'Positions',
        id: uuid(),
        icon: faBriefcase,
        to: '/cat/positions',
        permissions: 'CAT'
      },
      {
        title: 'Job Descriptions',
        id: uuid(),
        icon: faFileLines,
        to: '/jobdescriptions',
        permissions: 'JOB_DESC_DB'
      },
      {
        title: 'Reports',
        id: uuid(),
        icon: faChartColumn,
        permissions: 'CAT',
        submenu: [
          { title: 'Distribution Overview', id: uuid(), to: '/cat/views/distributionoverview' }
        ]
      }
    ]
  },
  {
    title: 'Admin',
    id: uuid(),
    permissions: 'MANAGEMENT',
    menu: [
      {
        title: 'Management',
        id: uuid(),
        icon: faGear,
        to: '/management'
      }
    ]
  }
]

export default menu
