import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { UseGraphqlOptions, useGraphQL } from 'src/hooks/useGraphQL'
import { AttachmentsQueryVariables } from '../../../gql/graphql'

const document = graphql(/* graphql */`
  query attachments($filter: FilterFindManyAttachmentInput, $sort: SortFindManyAttachmentInput, $limit: Int) {
    attachments {
      many(filter: $filter, sort: $sort, limit: $limit) {
        _id
        url
        filename
        _subtype {
          name
        }
        link
        _link {
          ... on Position {
            id
            title
            keywords
            _contract {
              name
            }
          }
        }
        updatedAt
        _updatedBy {
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type Attachments = NonNullable<NonNullable<NonNullable<TData>['attachments']>['many']>

const useAttachments = (variables?: AttachmentsQueryVariables, options?: UseGraphqlOptions<TData>, enabled: boolean = true): Attachments => {
  const internalVariables: AttachmentsQueryVariables = Object.assign({
    limit: 1000
  }, variables)
  // Set Cache Time to 14 mins to reset attachment urls that timeout after 15 mins
  const internalOptions: UseGraphqlOptions<TData> = Object.assign({
    cacheTime: 1000 * 14

  }, options)
  const { data } = useGraphQL(document, internalVariables, internalOptions)
  return data?.attachments?.many ?? []
}

export { useAttachments, document as attachmentsDocument }
